import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from '@components/image';

const Container = styled.article`
  flex-basis: 33.33333%;

  a {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 40.6%;
    position: relative;
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  img,
  video {
    width: 100%;
    transition: transform 0.2s; /* Animation */
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const Title = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
  opacity: 0;
  text-align: center;
`;

const Client = styled.p`
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  opacity: 0;
`;

const TitleContainer = styled.div`
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* opacity: 0; */
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.5s; /* Animation */

  @media (max-width: 600px) {
    background-color: rgba(0, 0, 0, 0.3);
  }

  p {
    @media (max-width: 600px) {
      opacity: 1;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &:hover p {
    opacity: 1;
  }
`;

export default function Thumbnail({ slug, client, title, thumbnail, location }) {
  return (
    <Container>
      <Link to={`/${slug}`} state={{ prevPath: location?.pathname }}>
        <Image src={thumbnail?.file?.url} alt={title} width={1200} />
        <TitleContainer>
          <Title>{title}</Title>
          <Client>{client.name}</Client>
        </TitleContainer>
      </Link>
    </Container>
  );
}
