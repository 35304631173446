import React from 'react';
import { Link } from 'gatsby';
import Logo from '@assets/images/lookout.png';
import styled from 'styled-components';

const Header = styled.header`
  background-color: #000;
  color: #fff;
  padding: 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  img {
    width: 270px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      width: 200px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  ul {
    display: flex;
    width: 270px;
    justify-content: space-between;
    @media (max-width: 600px) {
      width: 200px;
    }
  }
  li {
    a {
      text-transform: uppercase;
      color: white;
      text-decoration: none;
      font-weight: var(--font-weight-black);
      cursor: pointer;
      padding: 10px;
      display: block;

      @media (max-width: 600px) {
        font-size: 13px;
        padding: 5px;
      }
    }

    a.active {
      // color: black;
      // background-color: white;
      border-bottom: solid 2px white;
    }
  }
`;

export default function NewHeader() {
  return (
    <Header>
      <Link to="/">
        <img src={Logo} />
      </Link>
      <ul>
        <li>
          <Link activeClassName="active" to="/live-action">
            Live-Action
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/animation">
            Animation
          </Link>
        </li>
      </ul>
    </Header>
  );
}
