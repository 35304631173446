import React from 'react';
import styled from 'styled-components';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Container = styled.section`
  width: 100%;
  height: 600px;
  background-color: black;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

export default function HeroVideo({ file }) {
  const breakpoints = useBreakpoint();
  const showVideo = !breakpoints.sm;

  return (
    <Container>
      {showVideo && (
        <Video loop muted preload="auto" autoPlay>
          <source src={file} type="video/mp4" />
        </Video>
      )}
    </Container>
  );
}
